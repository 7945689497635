import React, { PropsWithChildren, createContext, useState } from 'react'

import { toggleBodyOverflow } from 'utils/toggleBodyOverflow'

export type ModalFormType = 'book'
export type FlatNameAndID = {
  name: string
  id: string
}

type ModalFormContextType = {
  modalFormType: ModalFormType
  modalFormVisible: boolean
  modalFormAssociatedFlat: FlatNameAndID | null
  modalFormBusinessUnit: boolean
  closeModalForm: () => void
  openModalForm: (
    formType: ModalFormType,
    flatNameAndId?: FlatNameAndID,
    isBusinessUnit?: boolean
  ) => void
}

const ModalFormContext = createContext<ModalFormContextType>({
  modalFormType: 'book',
  modalFormVisible: false,
  modalFormAssociatedFlat: null,
  modalFormBusinessUnit: false,
  closeModalForm: () => {},
  openModalForm: () => {},
})

export const ModalFormProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [modalFormType, setModalFormType] = useState<ModalFormType>('book')
  const [modalFormVisible, setModalFormVisible] = useState(false)
  const [modalFormAssociatedFlat, setModalFormAssociatedFlat] =
    useState<FlatNameAndID | null>(null)
  const [modalFormBusinessUnit, setModalFormBusinessUnit] = useState(false)

  const closeModalForm = () => {
    setModalFormVisible(false)
    toggleBodyOverflow('enable')
  }

  const openModalForm = (
    formType: ModalFormType,
    flatNameAndId?: FlatNameAndID,
    isBusinessUnit?: boolean
  ) => {
    setModalFormVisible(true)
    setModalFormType(formType)
    setModalFormAssociatedFlat(flatNameAndId ?? null)

    if (isBusinessUnit) {
      setModalFormBusinessUnit(true)
    } else {
      setModalFormBusinessUnit(false)
    }

    toggleBodyOverflow('disable')
  }

  return (
    <ModalFormContext.Provider
      value={{
        modalFormType,
        modalFormVisible,
        modalFormAssociatedFlat,
        modalFormBusinessUnit,
        closeModalForm,
        openModalForm,
      }}
    >
      {children}
    </ModalFormContext.Provider>
  )
}

export default ModalFormContext
