export const toggleBodyOverflow = (action: 'enable' | 'disable') => {
  if (typeof window !== `undefined`) {
    if (action === 'disable') {
      document.documentElement.style.overflow = 'hidden'
    }
    if (action === 'enable') {
      document.documentElement.style.overflow = 'auto'
    }
  }
}
