/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const React = require('react')
const { ThemeProvider } = require('styled-components')

const { default: theme } = require('styles/theme')
const { default: GlobalStyles } = require('styles/global')
const { BreakpointsProvider } = require('contexts/BreakpointsContext')
const { NavigationProvider } = require('contexts/NavigationContext')
const { ModalFormProvider } = require('contexts/ModalFormContext')
const { FormInfoSuccessProvider } = require('contexts/FormSuccessInfoContext')

exports.wrapRootElement = ({ element }) => (
  <BreakpointsProvider>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </BreakpointsProvider>
)

exports.wrapPageElement = ({ element }) => (
  <NavigationProvider>
    <BreakpointsProvider>
      <ModalFormProvider>
        <FormInfoSuccessProvider>
          <GlobalStyles />
          {element}
        </FormInfoSuccessProvider>
      </ModalFormProvider>
    </BreakpointsProvider>
  </NavigationProvider>
)
